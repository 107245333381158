<template>
  <teleport to='head'>
    <title>Mart Hesselink | Web Developer</title>

    <meta name="description" content="Hello, I am Mart Hesselink. Web Developer & Music Lover. Need a website? Contact me!."/>
    <meta name="keywords" content="Mart Hesselink, Web Developer, Enschede, Website, Developer, WordPress"/>
    <meta name="author" content="Mart Hesselink"/>

    <meta name="robots" content="index"/>
    <meta name="robots" content= "nofollow"/>

    <meta property="og:type" content="website">
    <meta property="og:title" content="Mart Hesselink | Web Developer">
    <meta property="og:description" content="Web Developer & Music Lover. Need a website? Contact me!">
    <meta property="og:image" content="../assets/logo.png">
    <meta property="og:url" content="https://marthesselink.nl/portfolio">
    <meta property="twitter:card" content="summary_large_image">

    <meta http-equiv="expires" content="86400"/>
  </teleport>
</template>

<script>
  export default {
    name: 'Meta'
  };
</script>
